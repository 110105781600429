<template>
  <div>
    <PageHeader :title="$t('backoffice.companies')" :items="breadcrumb" />
    <!-- Filtro -->
    <b-card class="mb-3 mt-3">
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <h4 class="text-center">Filtrar</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="my-1">
            <b-form-group label="CNPJ" label-for="filtroCnpj">
              <b-form-input
                id="filtroCnpj"
                type="text"
                v-model="filtroCnpj"
                @input="filtrarEmpresas"
                v-mask="'##.###.###/####-##'"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label="Razão Social" label-for="filtroRazaoSocial">
              <b-form-input
                id="filtroRazaoSocial"
                type="text"
                v-model="filtroRazaoSocial"
                @input="filtrarEmpresas"
              />
            </b-form-group>
          </b-col>
          <b-col md="4" class="my-1">
            <b-form-group label="Nome Fantasia" label-for="filtroNomeFantasia">
              <b-form-input
                id="filtroNomeFantasia"
                type="text"
                v-model="filtroNomeFantasia"
                @input="filtrarEmpresas"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content">
            <b-button @click="redirectPage(null)" variant="success">
              Cadastrar Empresa
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- Tabela de Empresas -->
    <b-card>
      <b-container fluid>
        <b-row>
          <b-col>
            <h4 class="titulo mb-3">Empresas</h4>
            <b-table
              :items="empresasFiltradas"
              :fields="tableFields"
              :striped="true"
              :hover="false"
              :bordered="false"
              :borderless="false"
              responsive="sm"
            >
              <template v-slot:cell(id)="row">
                <a class="underline-hover" @click="redirectPage(row.item)">
                  {{ row.value }}
                </a>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
import Vue from 'vue';
import VueMask from 'v-mask';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';
import moment from 'moment';
import PageHeader from '@/components/page-header';
import { listAll } from './listEmpresas.service';
import { i18n } from '../../main';

Vue.use(VueCookies);
Vue.use(VueToast);
Vue.use(moment);
Vue.use(VueMask);

export default {
  name: 'App',
  data() {
    return {
      empresas: [],
      filtroCnpj: '',
      filtroRazaoSocial: '',
      filtroNomeFantasia: '',
      empresasFiltradas: [],
      tableFields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'cnpj', label: 'CNPJ', sortable: true },
        { key: 'nome', label: 'RAZÃO SOCIAL', sortable: true },
        { key: 'fantasia', label: 'NOME FANTASIA', sortable: true }
      ],
      items: [
        {
          text: 'Backoffice',
          href: '#'
        },
        {
          text: 'Empresas',
          active: true
        }
      ]
    };
  },
  components: {
    PageHeader
  },
  computed: {
    breadcrumb() {
      return [
        {
          text: 'Backoffice',
          href: '/'
        },
        {
          text: i18n.tc('backoffice.companies'),
          active: true
        }
      ];
    }
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Empresas'
    }
  },
  methods: {
    // Método para buscar as empresas a partir do mock fornecido
    buscarEmpresas() {
      // Simulando uma chamada assíncrona para obter os dados
      setTimeout(() => {
        // Convertendo o mock para uma lista (se necessário)
        listAll().then((empresas) => {
          //console.log(empresas);
          this.empresas = empresas.content;
          this.filtrarEmpresas();
        });
      }, 500); // Simulando um pequeno atraso para fins ilustrativos
    },
    // Método para atualizar a lista de empresas com base nos filtros
    filtrarEmpresas() {
      //console.log(this.empresas);
      this.empresasFiltradas = this.empresas.filter((empresa) => {
        const cnpjMatch = empresa.cnpj.includes(this.filtroCnpj);
        const razaoSocialMatch = empresa.nome
          .toLowerCase()
          .includes(this.filtroRazaoSocial.toLowerCase());
        const nomeFantasiaMatch =
          empresa.fantasia != null &&
          empresa.fantasia
            .toLowerCase()
            .includes(this.filtroNomeFantasia.toLowerCase());

        return cnpjMatch && razaoSocialMatch && nomeFantasiaMatch;
      });
    },
    redirectPage(empresa) {
      //console.log(empresa);
      if (empresa === null) {
        this.$router.push({
          path: '/cadastro-empresas'
        });
      } else {
        this.$router.push({
          path: '/cadastro-empresas',
          query: { data: JSON.stringify(empresa) }
        });
      }
    }
  },
  mounted() {
    this.buscarEmpresas();
  }
};
</script>

<style>
.filtros {
  display: flex;
}

.form-group {
  flex-grow: 1;
  padding: 15px;
}

.titulo {
  text-align: center;
}

.btn.btn-success {
  width: 15%;
  margin: 5px;
}

.underline-hover:hover {
  text-decoration: underline !important;
}
</style>
